import React from 'react'
import Helmet from 'react-helmet'

import Metadata from '../../components/common/meta/MetaData'
import Layout from '../../components/en/common/Layout'
import SocialIcons from '../../components/common/SocialIcons'
import ContactForm from '../../components/en/sections/contact-form'

const privacyPolicy = ({ location }) => {
	return (
		<>
			<Metadata
				title="Privacy Policy"
				description="We specialize in: Software Development, Web Design, Video & Photography, Online Marketing, Shopify eCommerce, WordPress Development, E-Shop Development, SEM, SEO, PPC, Website Development, Performance Marketing."
				location={location}
				linkAlternate={false}
			/>
			<Helmet>
				<link rel="alternate" href="https://think-plus.gr/prosopika-dedomena/" hreflang="el-gr" />
				<link rel="alternate" href="https://think-plus.gr/en/privacy-policy/" hreflang="en" />
			</Helmet>
			<Layout>
				<article className="container">
					<header className="post__header">
						<SocialIcons type={'light'} position={'absolute'} />
						<div className="post__title orphan">
							<h1>PRIVACY STATEMENT</h1>
						</div>
					</header>
					<div className="post__full-content">
						<div className="container">
							<div className="content">
								<section className="content-body">
									<p>Effective date – Amendments</p>
									<p><strong>Version 1.0, Published in 01.01.2021</strong></p>
									<p>This Privacy Statement describes – inter allia- the use, disclosure and protection of your Personal data, your available options regarding your Personal Data and of how you can contact us. Where in this statement a reference is made in the term “the Company”, it shall mean either separately or/and jointly “THINK PLUS“ with a Tax Identification Number (T.I.N.) 800335902, having its principal operating office at Amarousion Attikis, 36 Chalandriou Street, as well as any other legal entity related to the aforementioned Company.</p>
									<p>The protection of your Personal Data is very important to us. Recently, we conducted a series of changes, reflecting the <strong>growing demands</strong> of the General Data Protection Regulation (hereinafter referred to as “GDPR”) of the European Union. Our aim is to be as honest and transparent as possible regarding the personal data that we collect, as well as the <strong>means of processing</strong> of the said Data. Our Company understands and shares your concerns related to your personal data.</p>
									<p>Our Company has a multi-year activity, experience and specialization in the provision marketing and digital products. This Privacy Statement,<strong> </strong>describes the types of data which are subjected to Processing by our Company, the way the said data are processed, as well as the purpose ofthe said processing. Additionally, in this privacy statement, you can find information and details regarding your Personal Data processing related with specific offered services for which, additional and related information are offered.The current privacy statement, governs every interaction with the Company.</p>
									<br />
									<h2>Personal Data We Collect</h2>
									<p>Personal data are described as the Data which can be used for the identification of a natural person.</p>
									<p>You may be asked to provide personal data in the context of any contact, transaction and -generally- every interaction with our Company and/or with a related legal entity of our Company. Our Company may exchange with its related legal entities your personal information and use them pursuant to the current Privacy Policy. They also may combine them with other information, in order to provide and improve our products and services as well as the content of the said services and/or the advertisement of our business activity.</p>
									<p>Our Company collects data from you, through your interaction with us.Our intention is to collect only the absolutely necessary Data, which are specific and appropriate for the intended purpose of the collection.</p>
									<p>The collection and -generally- the processing are conducted for different purposes which are described below. One of the saidpurposes is to conduct our business <strong>in an effective manner</strong> and to provide you the best experience with our products and services. Certain data are provided directly from you, for example when you contact us.</p>
									<p>We rely on various legal grounds and rights (“legal basis”) for the processing of your personal data, such as your consent, our legitimate interests, the necessity of concluding an agreement with you and/or performing our contract obligations to you, as well as our compliance with our legal duties for various purposes described below.</p>
									<p>Additionally, we may acquire data from third parties. We protect the data we receive from third parties in accordance with the described practices in this Notice, alongside with the possible additional restrictions enforced by the data provider.</p>
									<p>When you will be asked to provide your personal data, you can refuse. However, some of our Services require certain personal data, in order to respond and provide the product or the service. If you choose not to supply your Data which are necessary for the function and the provision of a service to you, you will not be able to use this specific service. Similarly, in case we are obliged to collect your personal data by law or for the purposes of concluding and performing a contract with you, and you do not provide your personal data, we will be unable to conclude and perform the contract.</p>
									<p>Among the data we collect, the following may be included:</p>
									<p><strong>Name and Contact Details</strong>. Your name and surname, email address, postal address, phone number and other similar contact details. In case you express your interest to work for the company we may collect the data of your curriculum vitae such as <strong>educational data</strong>, e.g. Academics, skills, knowledge of foreign languages, working experience etc.</p>
									<p><strong>Payment Data.</strong> Data for handling payments, such as the number of your payment instrument, as well as its security password which is correlated with the payment instrument. When you choose to conduct payments through electronic cards and similar means of payments, we may notify your data to Financial and Banking Institutions.</p>
									<p><strong>Interactions.</strong> Data related to the use of the Company’s Services from you. For instance, we collect notes from your discussions with us, details related to possible complaints or comments you submit, websites you visit and how and when you contact us. Interests and preferences, which help us to recommend you specific services interested to you. Numbers of visits to our website or other websites you visited before ours. Information collected from the use of cookies in your browser. The username of your social network, if you interacted with us via these channels, to help us answer your comments, questions and/or queries.</p>
									<p><strong>Video or Records.</strong> Records of events, activities in building and other locations of the Company. If you visit a location of the Company, such as our premises or attend a company’s event which is monitored, your image and voice message may be recorded.</p>
									<p><strong>Comments and Reviews.</strong> Information you provide to us and the content of the messages you send us, such as comments, survey data and reviews you write for a product.</p>
									<br />
									<h2>Collection and Processing of non – personal data.</h2>
									<p>We also collect data in a format excluding the automated direct connection with specific person. We maintain the right to collect, use, transfer and communicate non – personal data for every purpose.</p>
									<br />
									<h2>How we use your personal data</h2>
									<p>Our Company uses the collected personal data, for the purpose of providing our services to you. Our Company uses your personal data exclusively for the specific purposes of collection. In certain cases, and only after providing us your explicit consent, we use your contact details for sending promotional/campaign messages. We also use your data for the conduct of our business activities, which includes the analysis of our performance (benchmarking), the fulfillment of our legal obligations, the development of our workforce and the survey conduct. For the purposes above, we combine data collected from different environments. When we process personal data related to you, we do so, after obtaining your consent or/and as it is required in order to provide you the services you use, to conduct our business operations, to fulfill our contractual and legal obligations, to safeguard our system and client security or to serve Company’s other legal interests, as these are described in this section.</p>
									<p>Specifically, we use the data:</p>
									<ul>
										<li>To provide our services, including to inform, ensure and address problems. It also includes the notification of data, when it is necessary for the provision of services or for the conduct of the requested agreements.</li>
										<li>To improve and develop our services.</li>
										<li>To personalize our products and <strong>make</strong> recommendations to you.</li>
										<li>For commercial and promotional purposes.</li>
									</ul>
									<p>The following sections describe the data collection practices followed by our Company:</p>
									<p><strong>Contact us.</strong> Whereas the users of our website wish to contact us, they send their message through a form, filling additionally their full name, email and phone number. In these cases the Company uses your data to answer the submitted requests/questions. The information you share with us allow us to handle your requests and reply to you in the best possible way. We may also maintain record of your questions/requests in order to better respond in any future communication. We do this based on our contractual obligations towards you, our legal obligations and our legal interests aiming to provide the best possible service and be in a position to develop our services based on your personal experience.</p>
									<p><strong>Additional information in relation to the processing purposes:</strong></p>
									<p><strong>Protection of Rights.</strong> <span>CCTV Operating System:</span> To protect the security of our clients, areas, property and co-workers from criminal activities, we operate CCTV systems in our premises, that might record images. We do this based on our legitimate business interests. In case we discover any criminal activity or alleged criminal action via the use of CCTV, or in case of fraud and suspicious transactions, we will process these data for the purposes of prevention and detection of illegal actions. We want to protect our clients, employees and colleagues from criminal activities.<span> Payment Process and Fraudulent Transactions:</span> We do this based on our legitimate business interests. This also helps the protection of our clients from fraudulent activity.</p>
									<p><strong>Commercial Transactions. </strong>We use data for the purpose of executing transactions.</p>
									<p><strong>Reports and business activities.</strong> We use data to analyze our activities. This fact helps us tomake well-informed decision-making and create reports for the efficiency of our business activities.</p>
									<p><strong>Legal compliance</strong>. We use data for the purpose of legal compliance. For example, we use the age of our clients, to ensure that we perform our obligations to protect children’s personal data. We also process contact details and credentials, to help our clients to exercise their data protection rights.</p>
									<br />
									<h2>Why we share your personal data</h2>
									<p>We share your personal data upon your consent or as it is required, for the completion of any transaction or for the supply of any service you asked for or authorize us for. When you provide us with payment data, we will share your payment data with Bank Institutions or other similar entities that process payment transactions or provide other financial services, and for the prevention of fraud and for credit risk mitigation.</p>
									<p>In addition, we share your personal data with our partners, affiliates and co-operators.We also share our data with our suppliers or colleagues working for us, for the purposes as described in the current statement. In these cases, our colleagues shall comply with our personal data protection policy and safety requirements, and they are not allowed to use the personal data received from us for any other purpose.</p>
									<p>Finally, we will reserve, obtain access in, transfer, transmit and retain personal data, including their content, when we have reasons to believe in-good faith, that it is necessary for our compliance to valid legal procedure, which may also originate from national police forces or other state agencies.</p>
									<p>Our Company jointly-process your data with: a) Companies of our Group for the purposes and within the context of the terms mentioned hereinto, b) third party service providers processing personal data on behalf of the Company, for example (non-exhaustive) for the process of credit cards and payments, transfers and deliveries, hosting, management and maintenance of our database, email distribution, research and analysis, promotion management of the brand and services, Google, Facebook, as well as the management of certain services and data. When we use third party providers we conclude agreements obliging them to apply all the necessary technical and organizational measures for the protection of your personal data, d) other third bodies to the extent needed for the following purposes: (i) compliance with a state request, court decision or applicable law, (ii) prevention of unlawful use of websites and apps or breach of the Terms and Conditions of our website and apps and policies, (iii) our protection from third party claims, (iv) contribution for the prevention or investigation of fraud (e.g. counterfeiting), (d) to other third parties when you have provided your consent.</p>
									<p>To the companies above, as well as to any other third party with whom we share your data:</p>
									<ul>
										<li>We provide only the necessary information for the performance of their specific services.</li>
										<li>They may use your data only for the designated exact purposes laid down in our agreement with them.</li>
										<li>We closely work with them in order to ensure that your confidentiality is respected at any time.</li>
										<li>If we do not use their services any longer, all of your retaining data shall be deleted or anonymized.</li>
									</ul>
									<p>Data controllers on our behalf have been contractually agreed and bind with the Company:</p>
									<ul>
										<li>Treat your personal Data as strictly confidential.</li>
										<li>Not to transfer personal data to third parties without the permission of our Company.</li>
										<li>To impose and maintain appropriate safety measures.</li>
										<li>To comply with the legal framework concerning the protection of personal data, and especially with the Regulation 979/2016/EU (GDPR)</li>
									</ul>
									<p>Moreover, when using certain Social Media components on our website, you may create a public profile, which will include information such as, username, profile photo and city. You may also make join use of your content with your friends or the general public, including information for your interaction with the company.</p>
									<p><strong>Your Rights, Access and Control of your Personal Data</strong></p>
									<p>The General Data Protection Regulation provides a series of rights and choices, which we are bind to fulfill. Based on this, you may ask us:</p>
									<ul>
										<li>To inform you regarding the data we keep in relation to you and how we process them. If you wish, we may provide you with a copy of your Data without any additional charge (Right to Access)</li>
										<li>To rectify inaccuracies and/or mistakes, to complete incomplete data or to update your data (Right to Rectification)</li>
										<li>To erase your data, as long as there are no legitimate grounds for the processing (Right to erasure or ‘right to be forgotten’)</li>
										<li>To restrict the processing when a) the accuracy of the personal data is contested, b) you consider the processing as unlawful (but you do not wish the erasure of your data), c) when your data are no longer necessary for the purposes of the processing, d) for the time period pending the verification whether the legitimate grounds of the controller override those of the data subject.</li>
										<li>To object at any time for your own personal reasons, to the processing of data, especially for direct marketing and profiling reasons. Your objection may relate to your compliance with a decision we made by automated means. In the latter case, you may ask us to allow you to intervene (Right to objection - Automated individual decision-making)</li>
										<li>To provide your personal data in a structured, commonly used(usually machine-readable) format and have the right to transmit those data to another controller, if this is technically possible (Right to Data Portability)</li>
										<li>To do not process any longer your personal data, providing you the possibility to freely revoke your consent, which has been given to us.</li>
									</ul>
									<p>You may address your requests to our email address <a href="mailto:info@think-plus.gr">info@think-plus.gr.</a></p>
									<p>Our company will satisfy all of your requests within one (1) month. Only under extreme and rare circumstances, when the satisfaction of your rights is almost impossible for us, we will directly inform you, explaining to you the reasons of our relative inability.</p>
									<br />
									<h2>Cookies and Similar Technologies</h2>
									<p>A cookie is a small piece of text stored on your device for data retention and they may be recalled from a web server from where it has been installed. We use cookies and similar technologies to save and fulfil your preferences and settings to help you sing up for our services, to prevent fraud, to analyze the competency of our services and other legitimate purposes, as it is described below.<span>Our</span><span> websites</span> use cookies as part of facilitation and function of our online services, making your next visit and browsing experience easier and more useful to you. Cookies are small pieces of text (text files) send and stored on the user’s computer allowing websites to function without any obstructs and technical failures, to collect multiple user’s preferences, to recognize regular users, to facilitate user’s log in and of course for statistical purposes, in order to determine the locations of our website that are are useful, more popular for marketing reasons. You shall keep in mind that cookies are absolutely necessary for the effective and unobstructed function of our websites. Information stored in our website’s cookies are used exclusively from our website and partner businesses e.g. Google for statistical purposes. The user may further set up rules on its browser to do not store cookies, either generally or on a case by case basis, or to select automatic deletion of cookies after exiting the website.</p>
									<p>Our Company uses cookies and similar technologies for different proposes depending the environment or product, such as:</p>
									<ul>
										<li><strong>Preferences and Settings.</strong> We use cookies to store your preferences and setting on your device and to improve your browsing experience. By storing your preferences in cookies, for example your preferred language, it is no longer necessary to set your settings regularly. If you select to be exempted from advertisements based on your interest, we will save your selection to be exempted from a cookie on your device.</li>
										<li><strong>Storage of provided information on a website.</strong> We use cookies, to store information you shared. When you provide information to the Company, we store that information on a cookie to remember that information.</li>
										<li><strong>Comments.</strong> The Company may use cookies in order to facilitate you to make a comment on a website.</li>
										<li><strong>Analysis (Performance Cookies).</strong> It is possible to use our own cookies and/or third party cookies, as well as other identifications, to collect and evaluate usage and performance data. For example, we use cookies to count the number of single users on a website or service and to develop further statistics in relation to our products.</li>
									</ul>
									<p>In any case you retain the right to deactivate the use of cookies for efficiency purposes by selecting “Manage Cookies” on the pop-up window appeared during your web session on our websites. Under these circumstances it may be possible to experience delays. Furthermore, you may manage your browser settings, either completely or on a case-by-case basis or to select automatic deletion of cookies after exiting the website.</p>
									<ul>
										<li><strong>Advertisement.</strong>We may use our own and/or third-party cookies, as well as other identifications, to provide you personalized advertisements related to you, your preferences and interests. In any case you may deactivate the use of cookies for advertising purposes by selecting “Manage Cookies” on the pop-up window appeared on our websites during your web session. In this case, you will no longer receive advertisements related to your preferences.</li>
									</ul>
									<p>Most browsers accept cookies automatically, but they provide you monitoring choices allowing you to block or delete them. It is recommended to consult the personal data privacy policy or help documentation of your browser software to find instructions concerning the blockage or deletion of cookies in other browsers.</p>
									<p>Certain Company’s services depend on a cookie. If you select to block cookies, you will be unable to use these services.</p>
									<br />
									<h2>Other important personal data information</h2>
									<p>Additional information concerning the protection of personal data are described below, for example, how do we ensure your data and the time period of data retention.</p>
									<br />
									<h2>Personal Data Security</h2>
									<p>Our Company is obliged to safeguard your data. We use different technologies and security measures for the protection of your personal data from unauthorized access, use or disclosure. For example, we store your personal data you provide us with in computer systems in which the access is limited and there are located in controlled installations. We use various organizational and technical protective measures for your personal data. Our Company complies with the current personal data protection legal framework, including the legislation of data breach notification.</p>
									<p>The measures mentioned above are examined and amended when it is necessary.</p>
									<br />
									<h2>Where we store and process personal data</h2>
									<p>We do not transfer personal data outside of European Union.</p>
									<br />
									<h2>Retention of your personal data</h2>
									<p>Our Company retains personal data for or as long as it is necessary for product supply and completion of your requested transactions or for other legitimate purposes, such as the compliance with legal obligations, dispute resolution and enforcement of its decisions. Due to the fact that those needs may vary for different types of data, in the context of interaction with you or the usage of our products from you, the actual retention period may importantly differentiate.</p>
									<br />
									<h2>Collection of children’s personal data</h2>
									<p>We comply with the legislation and we do not allow children to registered to our website, when they have not reach the age defined by law. We will request parent’s consent for children participating to our Company’s experience and events. Even if parent’s consent is given, we will not willingly request from children under this age limit to provide more data than necessary for the product supply.</p>
									<p>Parents may alternate or revoke previous selections of granted consent, as well as to process or delete children personal data for whom they granted their consent or approval.</p>
									<br />
									<h2>Changes in the current personal data privacy statement</h2>
									<p>We will update this personal data privacy statement when necessary, to ensure transparency and respond to:</p>
									<ul>
										<li>Clients’ comments, regulatory authorities, sector representatives.</li>
										<li>Changes on our services.</li>
										<li>Changes to the operations or personal data processing policies.</li>
									</ul>
									<br />
									<h2>Contact us</h2>
									<p>If you have any considerations, complaints or questions concerning your personal data protection that you would like to address to the Data Protection Officer of the Company, please contact us by sending your message to <a href="mailto:info@think-plus.gr">info@think-plus.gr.</a> We will reply to your questions and considerations within 30 days. You may also report a consideration or submit a complaint to a data protection authority or other official and competent regulatory authorities.</p>
								</section>
							</div>
						</div>
					</div>
				</article>
				<ContactForm />
			</Layout>
		</>
	)
}

export default privacyPolicy
